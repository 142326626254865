import router from "@/router";
import axios from 'axios';
import * as fb from "../../firebase";
import crypto from 'crypto';

export default{
    state:{
        previewStreamList: [],
        previewStreamMap: new Map(),        
    },
    getters:{
        getpreviewStreamList: state => state.previewStreamList,
        getpreviewStreamMap: state => state.previewStreamMap
    },
    mutations:{
        setpreviewStreamMap(state, stream){
            state.previewStreamMap.set(stream.id, stream.data());
        },
        setpreviewStreamList(state, streamMap){
            state.previewStreamList = Array.from(streamMap.values());
        },
        removeStreamFromStreamMap(state, streamId){
            state.previewStreamMap.delete(streamId);
        }
    },
    actions:{
        fetchPreviewStream({state, commit}){
            return new Promise((response, reject) => {
                fb.liveStreamsCollection.where('status', '==', 'broadcasting').onSnapshot(result => {
                    if (result.empty) reject('No live streams found.')
                    result.docChanges().forEach(change => {
                        if (change.type === 'removed') {
                            commit('removeStreamFromStreamMap', change.doc.id);
                        }
                    })
                    result.forEach(doc => {
                        commit('setpreviewStreamMap', doc);
                    })
                    commit('setpreviewStreamList', state.previewStreamMap);
                    response('Live streams fetched succesfully');
                }, error => {
                    console.log(error.message);
                    reject(error.message);
                })

            })

        }

    }
}