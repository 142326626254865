var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-2",attrs:{"flat":""}},[_c('v-data-table',{attrs:{"items-per-page":-1,"items":_vm.getLiveStreamList,"headers":_vm.headers,"height":_vm.viewPortHeight / 2.5,"fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.streamName",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.streamName))]),_c('br'),_c('span',{staticClass:"caption grey--text"},[_c('strong',[_vm._v("ID:")]),_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.views",fn:function(ref){
var item = ref.item;
return [(item.status.toLowerCase() === 'broadcasting' || item.status.toLowerCase() === 'paused')?_c('span',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-eye-outline")]),_vm._v(" "+_vm._s(_vm.getCount(item.id))+" ")],1):_c('span',[_vm._v("NA")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status.toLowerCase() === 'broadcasting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.displayStreamPreview(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success darken-1"}},[_vm._v("mdi-play-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Preview")])]):_vm._e(),(item.status.toLowerCase() === 'broadcasting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.pauseStream(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"amber darken-1"}},[_vm._v("mdi-pause-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Pause")])]):_vm._e(),(item.status.toLowerCase() === 'paused')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.resumeStream(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success darken-1"}},[_vm._v("mdi-play-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Resume")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteStream(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('input',{attrs:{"type":"hidden","id":'stream' + item.id},domProps:{"value":'rtmp://ls.robotaisolutions.com/LiveApp/' + item.id}}),_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){return _vm.copyLink('#stream' + item.id)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-copy")]),_vm._v(" Copy ")],1)]}},{key:"item.goLive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":"success","input-value":item.goLive,"inset":"","dense":""},on:{"change":function($event){return _vm.goLive(item)}}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status.toLowerCase() === 'offline')?_c('span',[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" mdi-circle-slice-8 ")]),_vm._v(" Offline ")],1):(item.status.toLowerCase() === 'broadcasting')?_c('span',[_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" mdi-circle-slice-8 ")]),_vm._v(" Broadcasting ")],1):(item.status.toLowerCase() === 'paused')?_c('span',[_c('v-icon',{attrs:{"small":"","color":"amber darken-1"}},[_vm._v(" mdi-circle-slice-8 ")]),_vm._v(" Paused ")],1):_vm._e()]}}])})],1),_c('previewStream',{attrs:{"show":_vm.showPreview,"streamObj":_vm.previewObj},on:{"close":function($event){_vm.showPreview = !_vm.showPreview}}}),_c('v-dialog',{attrs:{"max-width":"35%"},model:{value:(_vm.showDeleteConfirmation),callback:function ($$v) {_vm.showDeleteConfirmation=$$v},expression:"showDeleteConfirmation"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("Are you sure?")]),(_vm.toDelete !== null)?_c('v-card-text',{staticClass:"d-flex justify-center"},[_vm._v("DELETE: "),_c('strong',[_vm._v(" \""+_vm._s(_vm.toDelete.streamName)+"\"")])]):_vm._e(),_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.showDeleteConfirmation = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"mx-2",attrs:{"elevation":"0","color":"error"},on:{"click":function($event){return _vm.confirmDeleteStream(_vm.toDelete.id)}}},[_vm._v("Delete")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex justify-center caption"},[_vm._v(" Recordings associated with this livestream \"WILL NOT\" be deleted. You can delete them seprately under the Recordings tab. ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }