<template>
  <div>
    <v-card class="pa-2" flat>
      <v-data-table
        :items-per-page="-1"
        :items="getLiveStreamList"
        :headers="headers"
        :height="viewPortHeight / 2.5"
        fixed-header                
        hide-default-footer
      >
        <template v-slot:item.streamName="{ item }">
          <strong>{{ item.streamName }}</strong>
          <br />
          <span class="caption grey--text">
            <strong>ID:</strong>
            {{ item.id }}
          </span>
        </template>
        <template v-slot:item.views="{ item }">
          <span v-if="item.status.toLowerCase() === 'broadcasting' || item.status.toLowerCase() === 'paused'">
            <v-icon small left>mdi-eye-outline</v-icon>
            {{ getCount(item.id) }}
          </span>
          <span v-else>NA</span>          
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="item.status.toLowerCase() === 'broadcasting'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="displayStreamPreview(item)"
                icon
              >
                <v-icon color="success darken-1">mdi-play-outline</v-icon>
              </v-btn>
            </template>
            <span>Preview</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.status.toLowerCase() === 'broadcasting'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="pauseStream(item)" icon>
                <v-icon color="amber darken-1">mdi-pause-circle</v-icon>
              </v-btn>
            </template>
            <span>Pause</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.status.toLowerCase() === 'paused'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="resumeStream(item)" icon>
                <v-icon color="success darken-1">mdi-play-circle</v-icon>
              </v-btn>
            </template>
            <span>Resume</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="deleteStream(item)" icon>
                <v-icon color="error" small>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>

        <template v-slot:item.link="{ item }">
          <input
            type="hidden"
            :id="'stream' + item.id"
            :value="'rtmp://ls.robotaisolutions.com/LiveApp/' + item.id"
          />
          <v-btn
            @click="copyLink('#stream' + item.id)"
            small
            color="primary"
            text
          >
            <v-icon left small>mdi-content-copy</v-icon>
            Copy
          </v-btn>
        </template>
        <template v-slot:item.goLive="{ item }">
          <v-switch
            color="success"
            :input-value="item.goLive"
            @change="goLive(item)"
            inset
            dense
          ></v-switch>
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="item.status.toLowerCase() === 'offline'">
            <v-icon small color="error"> mdi-circle-slice-8 </v-icon>
            Offline
          </span>
          <span v-else-if="item.status.toLowerCase() === 'broadcasting'">
            <v-icon small color="success"> mdi-circle-slice-8 </v-icon>
            Broadcasting
          </span>
          <span v-else-if="item.status.toLowerCase() === 'paused'">
            <v-icon small color="amber darken-1"> mdi-circle-slice-8 </v-icon>
            Paused
          </span>
        </template>
      </v-data-table>
    </v-card>
    <previewStream
      @close="showPreview = !showPreview"
      :show="showPreview"
      :streamObj="previewObj"
    ></previewStream>
    <v-dialog max-width="35%" v-model="showDeleteConfirmation">
      <v-card>
        <v-card-title class="d-flex justify-center">Are you sure?</v-card-title>
        <v-card-text v-if="toDelete !== null" class="d-flex justify-center"
          >DELETE: <strong> "{{ toDelete.streamName }}"</strong></v-card-text
        >
        <v-card-text class="d-flex justify-center">
          <v-btn
            @click="showDeleteConfirmation = false"
            outlined
            class="mx-2"
            color="primary"
            >Cancel</v-btn
          >
          <v-btn
            @click="confirmDeleteStream(toDelete.id)"
            elevation="0"
            class="mx-2"
            color="error"
            >Delete</v-btn
          >
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="d-flex justify-center caption">
          Recordings associated with this livestream "WILL NOT" be deleted. You
          can delete them seprately under the Recordings tab.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import previewStream from "@/components/previewStream.vue";
export default {
  name: "liveStreamsList",
  components: {
    previewStream,
  },
  data() {
    return {
      showDeleteConfirmation: false,
      toDelete: null,
      showPreview: false,
      previewObj: {},
      headers: [
        {
          text: "Name",
          align: "Start",
          value: "streamName",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "Stream Link",
          align: "start",
          value: "link",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "Stream Status",
          align: "start",
          value: "status",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "Go Live",
          align: "start",
          value: "goLive",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "Viewers",
          align: "Start",
          value: "views",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          class: "subtitle-2 font-weight-black ",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getLiveStreamList",
      "getAllStreamCountList",
      "getAllStreamCountMap",
    ]),
    viewPortHeight() {
      return window.innerHeight;
    },
  },
  methods: {
    getCount(id) {
      let item = this.getAllStreamCountList.find((stream) => stream[0] === id);
      if(item === undefined){
        return false
      }
      else{
        return item[1];
      }
      
    },
    displayStreamPreview(streamObj) {
      this.showPreview = true;
      this.previewObj = streamObj;
    },
    copyLink(item) {
      console.log("ITEM", item);
      let link = document.querySelector(item);
      link.setAttribute("type", "text");
      link.select();
      if (document.execCommand("copy")) {
        this.$store.dispatch("showSnackBar", {
          text: "Broadcast link copied",
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnackBar", {
          text: "Link could not be copied.",
          color: "error",
        });
      }
      link.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    goLive(streamObj) {
      this.$store.dispatch("goLive", streamObj).then(
        (response) => {
          this.$store.dispatch("showSnackBar", {
            text: response,
            color: "success",
          });
        },
        (error) => {
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    },
    pauseStream(streamObj) {
      this.$store.dispatch("pauseStream", streamObj).then(
        (response) => {
          this.$store.dispatch("showSnackBar", {
            text: response,
            color: "success",
          });
        },
        (error) => {
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    },
    resumeStream(streamObj) {
      this.$store.dispatch("resumeStream", streamObj).then(
        (response) => {
          this.$store.dispatch("showSnackBar", {
            text: response,
            color: "success",
          });
        },
        (error) => {
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    },
    deleteStream(streamObj) {
      this.showDeleteConfirmation = true;
      this.toDelete = streamObj;
    },
    confirmDeleteStream(streamID) {
      this.$store.dispatch("deleteStream", streamID).then(
        (response) => {
          this.showDeleteConfirmation = false;
          this.toDelete = null;
          this.$store.dispatch("showSnackBar", {
            text: response,
            color: "success",
          });
        },
        (error) => {
          this.showDeleteConfirmation = false;
          this.toDelete = null;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    },
  },
  mounted() {
    if (this.getLiveStreamList.length === 0) {
      this.$store.dispatch("fetchLiveStreams").then((response) => {
        this.$store.dispatch(
          "fetchAllActiveStreamCounts",
          this.getLiveStreamList
        );
        this.$store.dispatch("showSnackBar", {
          text: response,
          color: "success",
        });
      });
    }
  },
};
</script>