import axios from 'axios';
import { cookie } from 'request-promise';


export default {
    state: {
        recordingList: [],        
    },
    getters: {
        getRecordingList: state => state.recordingList,
    },
    mutations: {
        setRecordingList(state, list) {
            state.recordingList = list;
        },
        
    },
    actions: {        
        deleteRecording({ state }, recId) {
            return new Promise((response, reject) => {
                var config = {
                    method: 'delete',
                    url: 'https://ls.robotaisolutions.com:5443/LiveApp/rest/v2/vods/' + recId,
                    headers: {

                    }
                };

                axios(config)
                    .then(() => {
                        response('Recording deleted successully.')
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error);
                    });
            })

        },
        fetchRecordings({ commit }) {
            return new Promise((response, reject) => {
                var config = {
                    method: 'get',
                    url: 'https://ls.robotaisolutions.com:5443/LiveApp/rest/v2/vods/list/0/30',
                    headers: {

                    }
                };

                axios(config)
                    .then((result) => {
                        commit('setRecordingList', result.data)
                        response('Fetched recording list.')
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error);
                    });
            })

        },
        fetchServerSpace(state) {
            return new Promise((response, reject) => {
                var config = {
                    method: 'post',
                    url: 'https://us-central1-livestream-f78fe.cloudfunctions.net/apiV1/getSession/a763cd056f1b24057',
                    headers: {}
                };

                axios(config)
                    .then( (result) => {                        
                        response(result.data)                        
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error)
                    });
            })
        }
    }
}