import * as fb from "../../firebase";

export default {
    state: {
        dataStreamsList: [],
        dataStreamMap: new Map()
    },
    getters: {
        getDataStreamList: state => state.dataStreamsList,
        getDataStreamMap: state => state.dataStreamMap
    },
    mutations: {
        setDataStreamList(state, items) {
            state.dataStreamsList = Array.from(items.values())
        },
        setDataStreamMap(state, item) {
            state.dataStreamMap.set(item.id, item.data())
        },
        removeStreamFromMap(state, itemId) {
            state.dataStreamMap.delete(itemId);
        }
    },
    actions: {
        fetchDataStreams({ commit, state }) {
            return new Promise((response, reject) => {
                fb.viewerCounterCollection.onSnapshot(result => {
                    if (result.empty) reject('No data streams');
                    result.docChanges().forEach(change => {
                        if (change.type === 'removed') {
                            commit('removeStreamFromMap', change.doc.id);
                        }
                    })
                    result.forEach(doc => {
                        commit('setDataStreamMap', doc);
                    })
                    commit('setDataStreamList', state.dataStreamMap);
                    response('Fetched')
                })
            })
        }
    }

}