<template>
  <div>
    <navBar></navBar>
    <v-row class="ma-2">
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="5">
            <createStream></createStream>
          </v-col>
          <v-col cols="7">
            <dataGraph></dataGraph>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <liveStreamsList></liveStreamsList>
      </v-col>
    </v-row>
    <!-- <v-dialog
      v-model="showDialog"
      overlay-opacity="0.9"
      overlay-color="black"
      persistent
      width="30%"
    >
      <v-card class="pa-10">
        <v-form>
          <v-textField v-model="pin" placeholder="ENTER THE PIN" outlined > </v-textField>

          <v-btn @click="login()" color="primary" large block elevation="0">Login</v-btn>
        </v-form>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
import dataGraph from "@/components/dataGraph.vue";
import createStream from "@/components/createStream.vue";
import liveStreamsList from "@/components/liveStreamsList.vue";
export default {
  name: "dashboard",
  components: {
    navBar,
    liveStreamsList,
    createStream,
    dataGraph,
  },
  data() {
    return {
      pin: null,
      showDialog: true,
    };
  },
  methods:{
      login(){
          if(this.pin === '787898'){
              this.showDialog = false;
          }
      }
  }
};
</script>