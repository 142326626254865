export default {
    state: {
        snackBar: {},

    },
    getters: {
        snackBar: state => state.snackBar,

    },
    mutations: {
        setSnackBar(state, snackBar) {
            state.snackBar = snackBar;
        },

    },
    actions: {
        showSnackBar({ commit }, snackBar) {
            snackBar.show = true;
            snackBar.color = (snackBar.color === undefined) ? 'grey darken-2' : snackBar.color;
            snackBar.timeout = (snackBar.timeout === undefined) ? 5000 : snackBar.timeout;
            snackBar.icon = (snackBar.icon === undefined) ? 'mdi-information-outline' : snackBar.icon;
            commit('setSnackBar', snackBar);
        },

    }
}