<template>
  <v-card
    style="position: absolute; z-index: 9999; right: 0; margin: 40px 40px"
    color="rgb(0,0,0,0.3)"
    class="px-2"
  >
    <span class="caption white--text"
      ><v-icon small color="white">mdi-eye-outline</v-icon>
      {{ getViewerCount }}
    </span>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "viewerTracker",
  props: {
    streamObj: Object,
  },
  computed: {
    ...mapGetters(["getViewerCount"]),
  },
};
</script>