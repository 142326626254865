<template>
  <v-app>
    <v-main style="background-color: #f5f5f5 !important">
      <!-- <v-responsive :aspect-ratio="16 / 9"> -->
        <router-view></router-view>
      <!-- </v-responsive> -->
    </v-main>
    <snackBar></snackBar>
  </v-app>
</template>

<script>
import snackBar from "@/components/helperUI/snackBar.vue";
export default {
  name: "App",

  data: () => ({
    //
  }),
  components:{
    snackBar

  },
  mounted(){
    window['console']['log'] = function() {};
    console.warn = function () { };    
  }
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
</style>