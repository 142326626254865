<template>
  <v-col>
      <viewersCount :streamObj="streamObj"></viewersCount>
    <v-img
      v-if="streamObj.status === 'offline'"
      :aspect-ratio="16 / 9"
      :width="width"
      src="@/assets/endedPoster.png"
    >
    </v-img>
    <v-img
      v-if="streamObj.status === 'paused'"
      :aspect-ratio="16 / 9"
      :width="width"
      src="@/assets/pausePoster.png"
    >
    </v-img>
    <livestreamPlayer v-if="streamObj.status === 'broadcasting'" :streamObj="streamObj"></livestreamPlayer>    
  </v-col>
</template>

<script>
import livestreamPlayer from '@/components/livestreamPlayer.vue'
import viewersCount from "@/components/viewerTracker.vue";
export default {
  name: "streamChild",
  props: {
    streamObj: Object,
  },
  components:{
      livestreamPlayer,
      viewersCount
  },
  data(){
      return{
          width: window.innerWidth
      }
  },
  methods:{
  },  
  mounted(){
      this.$store.dispatch("createUserSession", this.streamObj);
      this.$store.dispatch("watchSessionStatus", this.streamObj);
  },  
};
</script>