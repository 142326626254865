<template>
  <v-container>
    <line-chart
      :chart-data="datacollection"
      :height="300"
      :width="850"
    ></line-chart>
  </v-container>
</template>

<script>
import LineChart from "@/components/AreaChart.vue";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      datacollection: {},
      dataSets: new Map(),      
      labels: [],
      intervalWatch: null,      
    };
  },
  computed: {
    ...mapGetters([
      "getLiveStreamList",
      "getAllStreamCountList",
      "getAllStreamCountMap",
    ]),
    viewPortHeight() {
      return window.innerHeight;
    },
  },
  watch: {
    getLiveStreamList(o, n) {
      // console.log("N:", n);
      n.forEach((item) => {
        if (
          item.status.toLowerCase() === "broadcasting" ||
          item.status.toLowerCase() === "paused"
        ) {
          // console.log("Item:", item.streamName);
          this.addToDataGraph(item);
        } else {
          if (this.dataSets.has(item.id)) {
            this.dataSets.delete(item.id);
          }
        }
      });
    },
  },
  methods: {
    getCount(id) {
      let item = this.getAllStreamCountList.find((stream) => stream[0] === id);
      return item[1];
    },
    addToDataGraph(item) {
      console.log("ADD TO DATA GRAPH");
      let color = Math.floor(Math.random() * 16777215).toString(16);
      this.dataSets.set(item.id, {
        label: item.streamName,
        borderColor: "#" + color,
        pointBackgroundColor: "white",
        borderWidth: 1,
        pointBorderColor: "white",
        backgroundColor: "#2B" + color,
        data: [0],
      });
    },
    iterator() {
      this.labels.push(moment().format("h:mm:ss A"));
      if (this.labels.length === 15) {
        this.labels.shift();
      }
      this.dataSets.forEach((val, key, map) => {
        console.log("MAP:", val);
        this.updateData(val, key, map);
      });

      this.datacollection = {
        labels: this.labels,
        datasets: Array.from(this.dataSets.values()),
      };
    },
    updateData(val, key, map) {
      let tempKey = key;
      let tempVal = val;
      let tempData = tempVal.data;
      tempData.push(this.getCount(key));
      if (tempData.length === 15) {
        tempData.shift();
        tempData[0] = 0;
      }
      tempVal.data = tempData;
      // console.log("TEMP VAL:", tempVal);
      this.dataSets.set(key, tempVal);
    },
    
  },
  mounted() {
    this.iterator();
    setInterval(() => {
      this.iterator();
    }, 15000);

    if (this.getLiveStreamList.length === 0) {
      this.$store.dispatch("fetchLiveStreams").then((response) => {
        this.$store.dispatch(
          "fetchAllActiveStreamCounts",
          this.getLiveStreamList
        );
      });
    }
  },
  filters: {
    formatTime(val) {
      if (!val) {
        return "-";
      }
      return moment.utc(val).format("HH:mm:ss");
    },
  },
};
</script>
