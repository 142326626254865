<template>
  <v-container ref="playerContainer" class="pa-0 ma-0" >
    <v-btn
      v-if="unmuteStatus"
      x-large
      @click="UnMuteStream"
      color="rgb(255,0,0,0.4)"
      elevation="0"
      width="500"
      class="white--text ma-0"
      block
      style="
        position: absolute;
        z-index: 9999;
        top: 20%;        
        margin: 40px 40px;
      ">
      Click to Un-mute
      </v-btn>
    <video
      :ref="streamObj.id"
      class="video-js vjs-default-skin vjs-big-play-centered"
    ></video>
  </v-container>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import "videojs-contrib-quality-levels";
import "@firescar96/videojs-quality-selector";
import { mapGetters } from "vuex";

export default {
  name: "livestreamPlayer",
  props: {
    streamObj: Object,
  },
  data() {
    return {
      broadcastStreamKey: 3,
      showBroadcast: false,
      width: window.innerWidth,
      player: null,
      unmuteStatus: false,
      options: {
        controls: true,
        liveui: true,
        muted: true,
        autoplay: true,
        width: window.innerWidth,
        height: window.innerHeight - 50,
        poster: "https://ls2.robotaisolutions.com/videoBackground.png",
      },
    };
  },
  computed: {
    ...mapGetters(["getViewerCount", "getStreamName"]),
  },
  methods: {
    UnMuteStream() {      
      this.unmuteStatus = false;
      this.player.muted(false);      
    },
    addQualityLevel(player) {
      let qualityLevel = player.qualityLevels();
      qualityLevel.on("addqualitylevel", (event) => {
        // console.log(event.qualityLevel);
        switch (event.qualityLevel.height) {
          case 240:
            event.qualityLevel.label = "240p";
            break;
          case 360:
            event.qualityLevel.label = "360p";
            break;
          case 480:
            event.qualityLevel.label = "480p";
            break;
          case 720:
            event.qualityLevel.label = "720p";
            break;
          case 1080:
            event.qualityLevel.label = "1080p";
            break;
        }
      });
    },

    initPlayer() {
      // console.log("ID:", this.$refs[this.streamObj.id]);
      this.player = videojs(this.$refs[this.streamObj.id], this.options, () => {
        this.player.src({
          src:
            // "https://ls.robotaisolutions.com:5443/LiveApp/streams/" +
            "https://d30yfhnee9s74k.cloudfront.net/streams/"+
            this.streamObj.id +
            "_adaptive.m3u8",
          type: "application/x-mpegURL",
          withCredentials: false,
        });
        this.addQualityLevel(this.player);
        this.player.httpSourceSelector();
        this.player.play();
        this.unmuteStatus = true;        
        this.player.error(null);
        this.autoScale();
      });
    },
    destroyPlayer() {
      // console.log("DESTROYED");
      if (this.player) {
        // console.log('DESTROY StreamID:', this.streamObj.id)
        this.player.dispose();
      }
    },
    autoScale() {
      window.addEventListener("resize", () => {        
        this.player.width(window.innerWidth);
        this.player.height(window.innerHeight - 50);
      });
    },
  },
  mounted() {
    if (this.streamObj.status === "broadcasting") {
      // setTimeout(() => {
      this.initPlayer();
      // }, 1000)
      // console.log('Current StreamID:', this.streamObj.id)
    }
  },
  beforeDestroy() {
    this.destroyPlayer();
  },
};
</script>
