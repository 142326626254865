<template>
<v-col>
     <v-progress-linear        
        style="position: absolute; z-index: 1"
        indeterminate
        color="red"
        height="7"
      >
      </v-progress-linear>
      <v-img :aspect-ratio="16/9" src="@/assets/poster.png"> </v-img>
</v-col>
    
</template>

<script>
export default {
    name: 'notStarted.vue',
    data(){
      return{
        width: window.innerWidth
      }
    }
}
</script>