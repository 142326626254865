import router from "@/router";
import axios from 'axios';
import * as fb from "../../firebase";
import crypto from 'crypto';

export default {
  state: {
    viewerCount: 0,
    currentStreamId: null,
    viewerId: crypto.randomBytes(50).toString("hex").slice(0, 5),
    allStreamCountMap: new Map(),
    allStreamCountList: [],
  },
  getters: {
    getViewerCount: state => state.viewerCount,
    getCurrentStreamId: state => state.currentStreamId,
    getAllStreamCountMap: state => state.allStreamCountMap,
    getAllStreamCountList: state => state.allStreamCountList,

  },
  mutations: {
    setViewerCount(state, count) {
      // console.log(stream)
      state.viewerCount = count;
    },
    setCurrentStreamId(state, streamId) {
      state.currentStreamId = streamId
    },
    setAllStreamCountMap(state, streamObj) {
      state.allStreamCountMap.set(streamObj.id, streamObj.count)
    },
    setAllStreamCountList(state, streamCountMap) {
      state.allStreamCountList = Array.from(streamCountMap);
      // console.log('ARRAY:', state.allStreamCountList)
    },
  },
  actions: {
    addViewerToSession({ state, dispatch }, streamObj) {
      let streamId = streamObj.id;
      let rdbRef = fb.firebase.database().ref("streamViewers/" + streamId + '/' + state.viewerId);
      let data = { updatedOn: fb.firebase.database.ServerValue.TIMESTAMP }
      rdbRef.get().then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
        } else {
          rdbRef.set(data)
        }
        dispatch('addUserToFirestore', { sId: streamId, pId: state.viewerId })
      }).catch((error) => {
        console.error(error);
      });


    },
    removeViewerFromSession({ state, dispatch }, streamObj) {
      let streamId = streamObj.id;
      let rdbRef = fb.firebase.database().ref("streamViewers/" + streamId + '/' + state.viewerId);
      rdbRef.remove();
      dispatch('removeUserFromFirestore', { sId: streamId, pId: state.viewerId })
    },
    removeStreamSession({ state }, streamObj) {
      let streamId = streamObj.id;
      let rdbRef = fb.firebase.database().ref("streamViewers/" + streamId);
      rdbRef.remove();
    },
    createUserSession({ state, dispatch }, streamObj) {
      let streamId = streamObj.id;
      let rdbRef = fb.firebase.database().ref("streamViewers/" + streamId + '/' + state.viewerId);
      let data = { updatedOn: fb.firebase.database.ServerValue.TIMESTAMP }
      fb.firebase.database().ref('.info/connected').on('value', (snapshot) => {
        if (snapshot.val() === false) {
          return;
        }
        rdbRef.onDisconnect().remove().then(() => {
          rdbRef.set(data)
          dispatch('addUserToFirestore', { sId: streamId, pId: state.viewerId })
        })
      })

    },
    watchSessionStatus({ state, commit }, streamObj) {
      let streamId = streamObj.id;

      let countRef = fb.firebase.database().ref("streamViewers/" + streamId)
      countRef.on('value', snapshot => {
        commit('setViewerCount', snapshot.numChildren())
      })

    },
    fetchAllActiveStreamCounts({ state, commit }, streams) {

      streams.forEach(stream => {
        let streamId = stream.id;

        let countRef = fb.firebase.database().ref("streamViewers/" + streamId)
        countRef.on('value', snapshot => {
          commit('setAllStreamCountMap', { id: streamId, count: snapshot.numChildren() })
          commit('setAllStreamCountList', state.allStreamCountMap)
        })
      })


    },
    addUserToFirestore({ state }, obj) {
      var config = {
        method: 'post',
        url: `https://us-central1-livestream-f78fe.cloudfunctions.net/apiV1/updateViewerCount/a763cd056f1b24057?streamId=${obj.sId}&pId=${obj.pId}&eventName=added`,
        headers: {}
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    removeUserFromFirestore({ state }, obj) {
      var config = {
        method: 'post',
        url: `https://us-central1-livestream-f78fe.cloudfunctions.net/apiV1/updateViewerCount/a763cd056f1b24057?streamId=${obj.sId}&pId=${obj.pId}&eventName=removed`,
        headers: {}
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    },    
  }
}