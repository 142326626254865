import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import dashboard from '@/views/dashboard.vue';
import player from '@/views/player.vue';
import recordings from '@/views/recordings.vue';
import recordingPlayer from '@/views/externalPlayer.vue';
import preview from '@/views/preview.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'player',
    component: player
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard
  },
  {
    path: '/recordings',
    name: 'recordings',
    component: recordings
  },
  {
    path: '/preview',
    name: 'preview',
    component: preview
  },
  {
    path: '/recordingPlayer',
    name: 'recordingPlayer',
    component: recordingPlayer
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.path === '/index.html'){
    next('/');
  }
  else{
    next();
  }
})

export default router
