<template>
  <v-row
    class="fill-height "
    no-gutters
    style="background-color: #ffff !important"
  >
    <notStarted v-if="!streamAvailable"></notStarted>
    <v-col v-if="streamAvailable">
      <v-row class="fill-height" v-if="showSelection">
        <v-col align="center" justify="center" align-self="center">
          <v-card outlined class="pa-16">
            <span class="text-h5">Please select the stream to view</span>
            <br>
            <span class="text-h6">(Seleccione la transmisión para ver)</span>
            <br />
            <br />
            <v-btn
              x-large
              color="#67112a"
              class="white--text ma-2 d-none d-md-flex "
              v-for="(item, i) in getPlayingStreamList"
              :key="i"
              @click="streamSelection(i)"
            >
              {{ item.streamName }}
            </v-btn>
            <v-btn              
              color="#67112a"
              class="white--text ma-2 d-none d-flex d-sm-flex d-md-none "
              v-for="(item, i) in getPlayingStreamList"
              :key="i"
              @click="streamSelection(i)"
            >
              {{ item.streamName }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>

      <v-tabs v-if="!showSelection" color="#67112a" v-model="tab">
        <v-tab
          active-class="activetab"
          v-for="(item, i) in getPlayingStreamList"
          :key="i"
          >{{ item.streamName }}</v-tab
        >
      </v-tabs>

      <!-- <v-tabs-items v-model="tab" v-if="!showSelection">
        <v-tab-item v-for="item in getPlayingStreamList" :key="item.id">
          <streamChild class="pa-0 ma-0" :streamObj="item"></streamChild>
        </v-tab-item>
      </v-tabs-items> -->
      <streamChild v-if="!showSelection" class="pa-0 ma-0" :streamObj="selectedStreamObj" :key="selectedStreamObj.id"></streamChild>
    </v-col>
  </v-row>
</template>

<script>
import notStarted from "@/components/notStarted.vue";
import streamChild from "@/components/streamChild.vue";
import crypto from "crypto";
import { mapGetters } from "vuex";
export default {
  name: "player",
  components: {
    notStarted,
    streamChild,
  },
  computed: {
    ...mapGetters(["getPlayingStreamList", "getPlayingStreamMap"]),
  },
  watch: {
    getPlayingStreamList(n) {
      this.checkIfStreamAvailable();
    },
    tab(n, o) {
      try {
        // console.log(`O: ${o}, N: ${n}`);
        this.selectedStreamObj = this.getPlayingStreamList[n];
        this.$store.dispatch(
          "addViewerToSession",
          this.getPlayingStreamList[n]
        );
        this.$store.dispatch(
          "removeViewerFromSession",
          this.getPlayingStreamList[o]
        );
      } catch (error) {
        // console.log(error)
      }
    },
  },
  data() {
    return {
      streamAvailable: false,
      tab: 0,
      showSelection: true,
      selectedStreamObj: null,
    };
  },
  methods: {
    streamSelection(item) {
      this.tab = item;
      this.selectedStreamObj = this.getPlayingStreamList[item];
      this.showSelection = false;
    },
    checkIfStreamAvailable() {
      if (this.getPlayingStreamList.length === 0) {
        this.streamAvailable = false;
      } else {
        this.streamAvailable = true;
      }
    },
  },
  mounted() {
    if (this.getPlayingStreamList.length === 0) {
      this.$store.dispatch("fetchPlayingStream").then(() => {
        // console.log("fetched stream list");
        this.checkIfStreamAvailable();
      });
    }
  },
};
</script>

<style lang="css" scoped>
.activetab {
  background-color: #67112a;
  color: #ffff !important;
}

</style>