import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyAA7PCq_M__gcq-PIyyZB6OgG2Y9Tt8LO8",
    authDomain: "livestream-f78fe.firebaseapp.com",
    databaseURL: "https://livestream-f78fe-default-rtdb.firebaseio.com",
    projectId: "livestream-f78fe",
    storageBucket: "livestream-f78fe.appspot.com",
    messagingSenderId: "817007891522",
    appId: "1:817007891522:web:017423028a1e8d202189db",
    measurementId: "G-P22YS8C9X1"
  };

  // Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.analytics().logEvent('notification_received');
const db = firebase.firestore();

const liveStreamsCollection = db.collection('liveStreams')
const viewerCounterCollection = db.collection('viewerCounter')

export{
    app,
    db,
    liveStreamsCollection,
    viewerCounterCollection,
    firebase
}

