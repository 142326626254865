<template>
  <v-card flat class="mx-16 mt-5 px-5 py-2">
    <v-progress-linear
      v-model="calculateSpacePercentage"
      :color="barColor"
      :indeterminate="freeSpace === null ? true : false"
      height="25"
    >
      <template v-if="freeSpace !== null" v-slot:default="{ value }">
        <strong>{{ value }}%</strong>
        &nbsp;
        <span class="caption grey--text text--darken-3">
          ({{ bytesToSize(inUseSpace) }}/{{ bytesToSize(usableSpace) }})
        </span>
      </template>
    </v-progress-linear>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "spaceMonitor",
  data() {
    return {
      available: 60,
      storeageObj: {},
      freeSpace: null,
      usableSpace: null,
      totalSpace: null,
      inUseSpace: null,
    };
  },
  computed: {
    ...mapGetters(['getPlayingStreamList']),
    calculateSpacePercentage() {
      return ((this.inUseSpace / this.usableSpace) * 100).toFixed(1);
    },
    barColor() {
      if (this.calculateSpacePercentage >= 70) {
        return "error";
      } else if (this.calculateSpacePercentage >= 50) {
        return "amber darken-1";
      } else {
        return "success";
      }
    },
  },
  methods: {
    checkSpace() {
      this.$store.dispatch("fetchServerSpace").then((response) => {
        this.storeageObj = response;
        (this.freeSpace = response.freeSpace),
          (this.usableSpace = response.usableSpace - 4044058999),
          (this.totalSpace = response.totalSpace),
          (this.inUseSpace = response.inUseSpace - 4044058999);
      });
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
  },
  mounted() {
    this.checkSpace();
    setInterval(() => {
      if(this.getPlayingStreamList.length !== 0){
        this.checkSpace();
      }
      
    }, 10000)
    
  },
};
</script>