<template>
  <v-dialog max-width="890" v-model="show" persistent>
    <v-card v-if="show">
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon class="ma-3" @click="closeDialog">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center"> Stream Preview: {{streamObj.streamName}}  </v-card-title>
      <v-divider></v-divider>
      <iframe width="890" height="500" :src="'https://ls.robotaisolutions.com:5443/LiveApp/play.html?name='+ streamObj.id +'&playOrder=hls'" frameborder="0" ></iframe>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "previewStream",
  props:{
      streamObj: Object,
      show: Boolean,
  },
  data(){
      return{          
      }
  },
  methods:{
    closeDialog(){
      this.show = false;
      this.$emit('close', false)
    }
  }
};
</script>