<template>
<v-card flat class="px-5">
      <v-card-title class="d-flex justify-center">
        Create New Stream
      </v-card-title>
      <v-row>
        <v-col>
          <v-text-field
            v-model="streamName"
            :rules="[(v) => !!v || 'Stream name cannot be emapty.']"
            outlined
            required
            dense
            label="Stream Name"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4" align="center">
          <v-btn @click="createStream()" color="primary" elevation="0">
            Create Stream
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    
</template>

<script>
export default {
    name:'createStream',
    data(){
        return{
            streamName: null,
        }
    },
    methods:{
        createStream() {
      if (this.streamName !== null && this.streamName !== "") {
        this.$store.dispatch("createStream", this.streamName).then(
          (response) => {
            this.$store.dispatch("showSnackBar", {
              text: response,
              color: "success",
            });
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
      }
    },

    }
}
</script>