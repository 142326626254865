<template>
  <iframe
    :width="width"
    :height="height"
    :src="
      'https://ls.robotaisolutions.com:5443/LiveApp/play.html?id=' +
      fileName +
      '&playOrder=vod'"
    frameborder="0"
    allowfullscreen
  ></iframe>
</template>

<script>
export default {
  name: "externalPlayer",
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      fileName: null,
    };
  },
  mounted() {
    this.fileName = this.$router.currentRoute.query.fileName;    
  },
};
</script>