<template>
  <v-app-bar color="primary" app flat>
      
      <span class="white--text text-h5">ARS Live Stream Dashboard</span>
      <v-spacer></v-spacer>
      <v-toolbar-items>        
        <v-btn text dark  to="/dashboard" > <v-icon left large>mdi-view-dashboard</v-icon> Dashboard</v-btn>        
        <v-btn text dark to="/recordings"> <v-icon left large>mdi-record-rec</v-icon> Recordings</v-btn>
        <v-btn text dark to="/preview" target="_blank"> <v-icon left >mdi-eye-outline</v-icon> Preview</v-btn>
      </v-toolbar-items>
  </v-app-bar>
</template>

<script>

export default {
  name: "navBar",
};
</script>

