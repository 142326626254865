<template>
  <div>
    <navBar></navBar>
    <spaceMonitor></spaceMonitor>
    <v-card flat class="mx-16 mt-5 px-5 py-2">
      <v-data-table
        sort-by="creationDate"
        sort-desc
        :headers="headers"
        :items="getRecordingList"
      >
        <template v-slot:item.streamName="{ item }">
          <strong>{{ item.streamName }}</strong>
        </template>
        <template v-slot:item.fileName="{ item }">
          {{ item.vodName }}
        </template>
        <template v-slot:item.duration="{ item }">
          {{ item.duration | formatTime }}
        </template>
        <template v-slot:item.size="{ item }">
          {{ bytesToSize(item.fileSize) }}
        </template>
        <template v-slot:item.creationDate="{ item }">
          {{ item.creationDate | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="displayRec(item)" icon>
                <v-icon color="success darken-1">mdi-play-outline</v-icon>
              </v-btn>
            </template>
            <span>Play</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="shareLink('#rec' + item.vodId)"
                icon
              >
                <v-icon color="primary lighten-1">mdi-share</v-icon>
              </v-btn>
              <input
                type="hidden"
                :id="'rec' + item.vodId"
                :value="fdn + '/recordingPlayer?fileName=' + item.vodName"
              />
            </template>
            <span>Share</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="downloadFile(item)"
                download="test"
                icon
              >
                <v-icon color="primary" small>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Download</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="deleteRecording(item)"
                icon
              >
                <v-icon color="error" small>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <playRecording
      @close="showRec = !showRec"
      :show="showRec"
      :recObj="recObj"
    ></playRecording>
    <v-dialog max-width="35%" v-model="showDeleteConfirmation">
      <v-card>
        <v-card-title class="d-flex justify-center">Are you sure?</v-card-title>
        <v-card-text class="d-flex justify-center"
          >Please enter the recording name to delete:
        </v-card-text>
        <v-card-text v-if="toDelete !== null" class="d-flex justify-center">
          <strong> {{ toDelete.streamName }} </strong>
        </v-card-text>
        <v-card-text class="d-flex justify-center px-16">
          <v-textField
            v-model="deleteRecName"
            outlined
            dense
            filled
            placeholder="Enter the recording name"
          ></v-textField>
        </v-card-text>
        <v-card-text class="d-flex justify-center">
          <v-btn
            @click="showDeleteConfirmation = false"
            outlined
            class="mx-2"
            color="primary"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="toDelete !== null"
            :disabled="deleteRecName === toDelete.streamName ? false : true"
            @click="confirmDeleteRecording(toDelete.vodId)"
            elevation="0"
            class="mx-2"
            color="error"
            >Delete</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
import playRecording from "@/components/playRecording.vue";
import spaceMonitor from "@/components/spanceMonitor.vue";
import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "recordings",
  components: {
    navBar,
    playRecording,
    spaceMonitor,
  },
  data() {
    return {
      showDeleteConfirmation: false,
      toDelete: null,
      deleteRecName: null,
      showRec: false,
      recObj: {},
      fdn: window.location.origin,
      headers: [
        {
          text: "Stream Name",
          align: "Start",
          value: "streamName",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "File Name",
          align: "start",
          value: "fileName",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "Duration",
          align: "start",
          value: "duration",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "Size",
          align: "start",
          value: "size",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "Date/Time",
          align: "Start",
          value: "creationDate",
          class: "subtitle-2 font-weight-black ",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          class: "subtitle-2 font-weight-black ",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getRecordingList"]),
  },
  watch: {
    showDeleteConfirmation(n) {
      if (!n) {
        this.toDelete = null;
        this.deleteRecName = null;
      }
    },
  },
  methods: {
    downloadFile(item) {
      // console.log('download')
      window.open(
        "https://ls.robotaisolutions.com:5443/LiveApp/streams/" + item.vodName
      );
      // axios({
      //   url: "https://ls.robotaisolutions.com:5443/LiveApp/streams/" + item.vodName,
      //   method: "GET",
      //   responseType: "blob",
      // }).then((response) => {
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   const link = document.createElement("a");
      //   link.href = url;
      //   link.setAttribute("download", item.streamName+ '.mp4');
      //   document.body.appendChild(link);
      //   link.click();
      // });
    },
    shareLink(item) {
      console.log("ITEM", item);
      let link = document.querySelector(item);
      link.setAttribute("type", "text");
      link.select();
      if (document.execCommand("copy")) {
        this.$store.dispatch("showSnackBar", {
          text: "Sharable link copied to clipboard",
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnackBar", {
          text: "Link could not be copied.",
          color: "error",
        });
      }
      link.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    displayRec(recObj) {
      this.showRec = true;
      this.recObj = recObj;
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    deleteRecording(recObj) {
      this.showDeleteConfirmation = true;
      this.toDelete = recObj;
    },
    confirmDeleteRecording(itemId) {
      console.log(`TO DELETE: ${itemId}`);
      this.$store.dispatch("deleteRecording", itemId).then(
        (response) => {
          this.showDeleteConfirmation = false;
          this.toDelete = null;
          this.deleteRecName = null;
          this.$store.dispatch("fetchRecordings");
          this.$store.dispatch("showSnackBar", {
            text: response,
            color: "success",
          });
        },
        (error) => {
          this.showDeleteConfirmation = false;
          this.toDelete = null;
          this.deleteRecName = null;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    },
  },
  mounted() {
    this.$store.dispatch("fetchRecordings").then(
      (response) => {
        // console.log(this.getRecordingList);
        this.$store.dispatch("showSnackBar", {
          text: response,
          color: "success",
        });
      },
      (error) => {
        this.$store.dispatch("showSnackBar", {
          text: error,
          color: "error",
        });
      }
    );
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).calendar();
    },
    formatTime(val) {
      if (!val) {
        return "-";
      }
      return moment.utc(val).format("HH:mm:ss");
    },
  },
};
</script>