import router from "@/router";
import axios from 'axios';
import * as fb from "../../firebase";
import crypto from 'crypto';

export default{
    state:{
        playingStreamList: [],
        playingStreamMap: new Map(),
    },
    getters:{
        getPlayingStreamList: state => state.playingStreamList,
        getPlayingStreamMap: state => state.playingStreamMap
    },
    mutations:{
        setPlayingStreamMap(state, stream){
            state.playingStreamMap.set(stream.id, stream.data());
        },
        setPlayingStreamList(state, streamMap){
            state.playingStreamList = Array.from(streamMap.values());
        },
        removeStreamFromStreamMap(state, streamId){
            state.playingStreamMap.delete(streamId);
        }
    },
    actions:{
        fetchPlayingStream({state, commit}){
            return new Promise((response, reject) => {
                fb.liveStreamsCollection.where('goLive', '==', true).onSnapshot(result => {
                    if (result.empty) reject('No live streams found.')
                    result.docChanges().forEach(change => {
                        if (change.type === 'removed') {
                            commit('removeStreamFromStreamMap', change.doc.id);
                        }
                    })
                    result.forEach(doc => {
                        commit('setPlayingStreamMap', doc);
                    })
                    commit('setPlayingStreamList', state.playingStreamMap);
                    response('Live streams fetched succesfully');
                }, error => {
                    console.log(error.message);
                    reject(error.message);
                })

            })

        }

    }
}