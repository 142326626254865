import router from "@/router";
import axios from 'axios';
import * as fb from "../../firebase";
import crypto from 'crypto';

export default {
    state: {
        liveStreamList: [],
        liveStreamMap: new Map(),

    },
    getters: {
        getLiveStreamList: state => state.liveStreamList,
        getLiveStreamMap: state => state.liveStreamMap,

    },
    mutations: {
        setLiveStreamMap(state, stream) {
            state.liveStreamMap.set(stream.id, stream.data());
        },
        setLiveStreamList(state, streamMap) {
            state.liveStreamList = Array.from(streamMap.values());
        },
        removeStreamFromStreamMap(state, streamId) {
            state.liveStreamMap.delete(streamId)
        }

    },
    actions: {
        createStream({ state }, streamName) {
            return new Promise((response, reject) => {
                const streamId = crypto.randomBytes(50).toString("hex").slice(0, 10);
                var data = JSON.stringify({
                    "streamId": streamId,
                    "status": "created",
                    "playListStatus": null,
                    "type": "liveStream",
                    "publishType": null,
                    "name": streamName,
                    "description": null,
                    "publish": true,
                    "date": 1645217098344,
                    "plannedStartDate": 0,
                    "plannedEndDate": 0,
                    "duration": 0,
                    "endPointList": null,
                    "playListItemList": [],
                    "publicStream": true,
                    "is360": false,
                    "listenerHookURL": "https://us-central1-livestream-f78fe.cloudfunctions.net/apiV1/mediaWebHooks/a763cd056f1b24057",
                    "category": null,
                    "ipAddr": null,
                    "username": null,
                    "password": null,
                    "quality": null,
                    "speed": 0,
                    "streamUrl": null,
                    "originAdress": "127.0.1.1",
                    "mp4Enabled": 0,
                    "webMEnabled": 0,
                    "expireDurationMS": 0,
                    "rtmpURL": "rtmp://127.0.1.1/LiveApp/clara",
                    "zombi": false,
                    "pendingPacketSize": 0,
                    "hlsViewerCount": 0,
                    "webRTCViewerCount": 0,
                    "rtmpViewerCount": 0,
                    "startTime": 0,
                    "receivedBytes": 0,
                    "bitrate": 0,
                    "userAgent": "N/A",
                    "latitude": null,
                    "longitude": null,
                    "altitude": null,
                    "mainTrackStreamId": null,
                    "subTrackStreamIds": [],
                    "absoluteStartTimeMs": 0,
                    "webRTCViewerLimit": -1,
                    "hlsViewerLimit": -1,
                    "subFolder": null,
                    "currentPlayIndex": 0,
                    "metaData": ""
                });

                var config = {
                    method: 'post',
                    url: 'https://ls.robotaisolutions.com:5443/LiveApp/rest/v2/broadcasts/create',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(config)
                    .then(() => {
                        // console.log(JSON.stringify(response.data));
                        fb.liveStreamsCollection.doc(streamId).set({
                            id: streamId,
                            streamName: streamName,
                            createdOn: new Date(),
                            updatedOn: new Date(),
                            status: 'offline',
                            goLive: false,
                        })
                            .then(() => {
                                console.log(`Stream ID: ${streamId} with Stream Name: ${streamName} Created successfully!`)
                                response('Stream Created')
                            })
                            .catch(error => {
                                console.log(error)
                                reject(error);
                            })
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error);
                    });
            })
        },
        fetchLiveStreams({ state, commit }) {
            return new Promise((response, reject) => {
                fb.liveStreamsCollection.onSnapshot(result => {
                    if (result.empty) reject('No live streams found.')
                    result.docChanges().forEach(change => {
                        if (change.type === 'removed') {
                            commit('removeStreamFromStreamMap', change.doc.id);
                        }
                    })
                    result.forEach(doc => {
                        commit('setLiveStreamMap', doc);
                    })
                    commit('setLiveStreamList', state.liveStreamMap);
                    response('Live streams fetched succesfully');
                }, error => {
                    console.log(error.message);
                    reject(error.message);
                })

            })
        },
        deleteStream({ state }, streamId) {
            return new Promise((response, reject) => {
                var config = {
                    method: 'delete',
                    url: 'https://ls.robotaisolutions.com:5443/LiveApp/rest/v2/broadcasts/' + streamId,
                    headers: {}
                };

                axios(config)
                    .then((result) => {
                        if (result.data.success) {
                            fb.liveStreamsCollection.doc(streamId).delete()
                                .then(() => {
                                    response('Deleted Successfully')
                                })
                        }
                        else {
                            reject(result.data.message)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error)
                    });
            })
        },
        goLive({ dispatch }, streamObj) {
            return new Promise((response, reject) => {
                fb.liveStreamsCollection.doc(streamObj.id).update({
                    goLive: !streamObj.goLive,
                    updatedOn: new Date()
                })
                    .then(() => {
                        if (!streamObj.goLive) {
                            response('Steaming STARTED on website.')
                        }
                        else {
                            response('Steaming ENDED on website.')
                            dispatch('removeStreamSession', streamObj)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        pauseStream({ state }, streamObj) {
            return new Promise((response, reject) => {
                fb.liveStreamsCollection.doc(streamObj.id).update({
                    status: 'paused',
                    updatedOn: new Date()
                })
                    .then(() => {
                        response('Steaming PAUSED on website.')
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        resumeStream({ state }, streamObj) {
            return new Promise((response, reject) => {
                fb.liveStreamsCollection.doc(streamObj.id).update({
                    status: 'broadcasting',
                    updatedOn: new Date()
                })
                    .then(() => {
                        response('Steaming RESUMED on website.')
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }

    }
}