var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('navBar'),_c('spaceMonitor'),_c('v-card',{staticClass:"mx-16 mt-5 px-5 py-2",attrs:{"flat":""}},[_c('v-data-table',{attrs:{"sort-by":"creationDate","sort-desc":"","headers":_vm.headers,"items":_vm.getRecordingList},scopedSlots:_vm._u([{key:"item.streamName",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.streamName))])]}},{key:"item.fileName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vodName)+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(item.duration))+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bytesToSize(item.fileSize))+" ")]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.creationDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.displayRec(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success darken-1"}},[_vm._v("mdi-play-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Play")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.shareLink('#rec' + item.vodId)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v("mdi-share")])],1),_c('input',{attrs:{"type":"hidden","id":'rec' + item.vodId},domProps:{"value":_vm.fdn + '/recordingPlayer?fileName=' + item.vodName}})]}}],null,true)},[_c('span',[_vm._v("Share")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"download":"test","icon":""},on:{"click":function($event){return _vm.downloadFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteRecording(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])})],1),_c('playRecording',{attrs:{"show":_vm.showRec,"recObj":_vm.recObj},on:{"close":function($event){_vm.showRec = !_vm.showRec}}}),_c('v-dialog',{attrs:{"max-width":"35%"},model:{value:(_vm.showDeleteConfirmation),callback:function ($$v) {_vm.showDeleteConfirmation=$$v},expression:"showDeleteConfirmation"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("Are you sure?")]),_c('v-card-text',{staticClass:"d-flex justify-center"},[_vm._v("Please enter the recording name to delete: ")]),(_vm.toDelete !== null)?_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('strong',[_vm._v(" "+_vm._s(_vm.toDelete.streamName)+" ")])]):_vm._e(),_c('v-card-text',{staticClass:"d-flex justify-center px-16"},[_c('v-textField',{attrs:{"outlined":"","dense":"","filled":"","placeholder":"Enter the recording name"},model:{value:(_vm.deleteRecName),callback:function ($$v) {_vm.deleteRecName=$$v},expression:"deleteRecName"}})],1),_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.showDeleteConfirmation = false}}},[_vm._v(" Cancel ")]),(_vm.toDelete !== null)?_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.deleteRecName === _vm.toDelete.streamName ? false : true,"elevation":"0","color":"error"},on:{"click":function($event){return _vm.confirmDeleteRecording(_vm.toDelete.vodId)}}},[_vm._v("Delete")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }